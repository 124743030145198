import React from 'react'
import Truncate from 'react-truncate';
import { Link } from 'gatsby'

const Article = ({title,date,content,slug}) => {
    return (
    <>
    <div className="w-full font-light text-xl text-joker px-6 italic mt-3 ">
                <span className="text-black dark:text-gray-400 mr-2 text-xl italic">{date}</span>

                <Link className="hover:text-black dark:text-gray-400" to={`/${slug}`}>{title}</Link>
            </div>
            <p className="text-gray-800 font-sans text-base px-6 mb-5 mt-2 leading-relaxed dark:text-gray-300">
            <Truncate lines={2}>
            
            {content}
            
            
            </Truncate>
            </p>
            <div className="hr-dotted"></div>
            </>
    )
    
}

export default Article