import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Article from './ArticleHome'

const query = graphql`
{
   allStrapiArticle(limit: 3) {
        nodes {
        id
        title
        date
        content
        slug

        }
    }
}
`

const ArticleHome = () => {
const {allStrapiArticle:{nodes:articles}, } = useStaticQuery(query)
return (
    <div className="w-full md:w-1/2 md:-mt-16 p-6 flex flex-col flex-grow flex-shrink">
    <div className="flex-1 flex-row rounded-t rounded-b-none overflow-hidden ">
    <p className="w-full text-4xl md:text-4xl font-medium px-6 uppercase text-joker dark:text-white">blog</p>
        {
        articles.map(item => {
            return  <Article key={item.id} {...item}></Article>
            
        })}
    
    </div>
    
    </div>
 )
}

export default ArticleHome

