import React from "react"
import Layout from "../components/layout"
import Rezension from "../components/home/rezensionshome"
import Article from "../components/home/articlesHome"
import Zitat from "../components/home/ZitatsHome"
import SEO from "../components/seo"
import Banner from "../components/home/Banner"
import Subscribe from "../components/home/Subscribe"

const IndexPage = () => (
  <Layout>
    <SEO title="Lese Joker" description="Lese Joker Buch Blog" />
    <Banner />
    <div className="hr"></div>
    <div className="container px-4 md:px-0 max-w-6xl mx-auto mt-12">
      <div className="flex flex-wrap justify-between pt-12 -mx-6">
        <Rezension />
        <Article />
      </div>
    </div>
    <div className="hr"></div>
    <Zitat />

    <Subscribe />
  </Layout>
)

export default IndexPage
