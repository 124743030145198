import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const Rezension = ({slug,titel,coverimg}) => {
    return (

        <div className="w-full font-light text-xl text-joker px-6 italic mt-3 ">
        <div className="flex flex-wrap border-b border-blue-tial-100">
          <div className="flex w-full m-4">
            <div className="flex items-center"> 
            <Link to={`/${slug}`}><Img fixed={coverimg.childImageSharp.fixed}/></Link>
              <div className="flex flex-col p-4">
                <h3 className="font-bold text-md text-tial-400 dark:text-gray-400">{titel}</h3>
              </div>
            </div>
            <div className="flex flex-1 flex-grow w-full items-center justify-end">
					<div className="flex items-center mr-2">
					<Link className="text-lg font-semibold text-joker-400 hover:text-joker-600 dark:text-gray-400" to={`/${slug}`}>Mehr Lesen</Link>	
						
					</div>
				</div>
          </div>
        </div>
    </div>
           
    )
    
}

export default Rezension