import React from 'react'

const Zitat = ({text,author}) => {
    return (
    <>
        <div className="flex-1 flex-row bg-white dark:bg-blue-800 rounded-t rounded-b-none overflow-hidden shadow-lg border-joker border-t-8">

        <blockquote className="w-full font-light text-2xl text-joker dark:text-white px-6 italic text-right">
        {text}
        </blockquote>
        </div>
        <div className="flex-none mt-auto bg-white dark:bg-blue-800 rounded-b rounded-t-none overflow-hidden shadow-lg p-6">
        <div className="flex items-center justify-between">
        <p className="text-gray-600 dark:text-gray-300 text-xs md:text-sm">{author}</p>
        </div>

        </div>
            </>
    )
    
}

export default Zitat