import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Zitat from './ZitatHome'

const query = graphql`
{
  allStrapiZitat(limit: 1, sort: {fields: created_at, order: DESC}) {
    nodes {
      text
      author
      id
    }
  }
}
`

    
const ZitatHome = () => {
    const {allStrapiZitat:{nodes:zitats}, } = useStaticQuery(query)
    return (
      <div className="container md:px-0 mx-auto mt-12 mb-6">

{
           zitats.map(item => {
            return  <Zitat key={item.id} {...item}></Zitat>
            
           })}        
      </div>
 )
}

export default ZitatHome