import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

function Subscribe()  {
    const data = useStaticQuery(graphql`
    {
        banner: strapiHomeslide {
            title
            subtitle
            image {
                childImageSharp {
                    fluid(maxWidth: 1300) {
                        ...GatsbyImageSharpFluid
                    }
                    }
            }
            
          }
        
    }
    `

    )
        return (
            <div className="subscribe-main subscribe-it" style={{
                backgroundImage: `url(${data.banner.image.childImageSharp.fluid.src})`
                }} >
                <div className=" p-10  py-20  flex  flex-col  flex-wrap  justify-center  content-center">
                    <div className=" m-0  p-0  text-3xl  text-white  antialiased  text-center">Get Our Updates</div>
                    <div className=" m-0  p-0  text-xl  text-white  antialiased  text-center">Find out about events and other news</div>
                    <div className=" mt-3  flex  flex-row  flex-wrap">
                    <input type="text" className=" text-gray-600  w-2/3  p-2  rounded-l-lg" placeholder="lese@mail.com"/>
                    <button className="w-1/3 subscribe-button" type="button">Subscribe</button>
                    </div>
                </div>
                </div>
        )
    
}

export default Subscribe
