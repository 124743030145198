import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const BannerHome = ({ banner }) => {
    const data = useStaticQuery(graphql`
    {
        banner: strapiHomeslide {
            title
            subtitle
            image {
                childImageSharp {
                    fluid(maxWidth: 1300) {
                        ...GatsbyImageSharpFluid
                    }
                    }
            }
            
          }
        
    }
    `

    )
    return (
        
        <div
        className="sm:bg-cover bg-center bg-fixed  h-auto text-white py-24 px-10 object-fill"
            style={{
            backgroundImage: `url(${data.banner.image.childImageSharp.fluid.src})`
            }}
            >

            <div className="py-10">
            <h1 className="text-center font-serif font-bold text-base sm:text-3xl md:text-xl lg:text-5xl xl:text-6xl text-white">
            {data.banner.title}
            </h1>
            <h2 className="text-center font-serif text-3xl text-white">{data.banner.subtitle}
            </h2>
            </div>
            
        </div>

 )
}

export default BannerHome



