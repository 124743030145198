import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Rezension from './RezensionHome'

const query = graphql`
{
  allStrapiRezension {
    nodes {
      id
      titel
      slug
      klappentext
      coverimg {
        childImageSharp {
          fixed(width: 80, height: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
}
`

const RezensionHome = () => {
  const {allStrapiRezension:{nodes:rezenhome}, } = useStaticQuery(query)
  return (

  <div className="w-full md:w-1/2 p-6 -mt-16 flex flex-col border-joker-100 border-r-2">
    <h2 className="w-full text-4xl md:text-4xl font-medium px-6 uppercase dark:text-white text-center">Rezension</h2>
    {
           rezenhome.map(item => {
  
            return  <Rezension key={item.id} {...item}></Rezension>
      })}
  </div>
                 
  )
}
export default RezensionHome
